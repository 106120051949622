import React from 'react';
import Hero from "../../components/Hero.jsx";
import {useTranslation} from "react-i18next";
import GoogleMap from "../../components/GoogleMap.jsx";
import KontaktForma from "./KontaktForma.jsx";
import {realFilePath} from "../../libs/utils";

function Kontakt(props) {
    const {t} = useTranslation("locals", {keyPrefix: "kontakt", useSuspense: true});

    return (
        <>
            <Hero title={"Kontakt"} background={realFilePath("hero_contact.jpg")}>
                <h1>{t("title")}</h1>
            </Hero>

            <section className="kontakt container py grid grid-1-2">
                <article>
                    <img style={{width: "100%"}} src={realFilePath("contact_us.jpg")} alt="contact us"/>
                </article>
                <article>
                    <div>
                        <dl>
                            <dt>{t("adresa.title")}</dt>
                            <dd>
                                {t("adresa.value", {returnObjects: true}).map((str, index) => {
                                    return <span key={index}>{str}</span>;
                                })}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{t("telefon")}:</dt>
                            <dd><a href="tel:+3810114140420">+381 (011) 41 40 420</a></dd>
                            <dt>{t("faks")}:</dt>
                            <dd>+381 (011) 41 40 429</dd>
                        </dl>
                    </div>
                    <div>
                        <dl>
                            <dt>{t("email")}</dt>
                            <dd><a href="mailto:office@fim.rs">office@fim.rs</a></dd>
                            <dt>{t("prijava")}</dt>
                            <dd><a href="mailto:prijava@fim.rs">prijava@fim.rs</a></dd>
                            <dt>{t("slOS")}</dt>
                            <dd><a
                                href="mailto:studentska.sluzba@fim.rs">studentska.sluzba@fim.rs</a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>{t("slDS")}</dt>
                            <dd><a href="mailto:master@fim.rs">master@fim.rs</a></dd>
                            <dt>{t("karijera")}</dt>
                            <dd><a href="mailto:karijera@fim.rs">karijera@fim.rs</a></dd>
                            <dt>{t("saradnja")}</dt>
                            <dd><a href="mailto:international@fim.rs">international@fim.rs</a></dd>
                        </dl>
                    </div>
                    <KontaktForma t={t}/>
                </article>
            </section>
            <GoogleMap/>
        </>
    );
}

export default Kontakt;