export const events = [
    {
        name: "Početak nastave u zimskom semestru - sve godine studija",
        start: "2024-09-30",
        end: "2024-09-30",
        type: "pocetak"
    },
    {
        name: "Kraj nastave u zimskom semestru - sve godine studija",
        start: "2025-01-24",
        end: "2025-01-24",
        type: "kraj"
    },
    {
        name: "Nadoknada nastave",
        start: "2025-01-27",
        end: "2025-01-31",
        type: "nadoknada"
    },
    {
        name: "Overa semestra - sve godine studija",
        start: "2025-01-29",
        end: "2025-01-31",
        type: "overaSemestra"
    },
    {
        name: "Januarsko-februarski ispitni rok",
        start: "2025-02-03",
        end: "2025-02-14",
        type: "ispitniRok"
    },
    {
        name: "Kraj zimskog semestra",
        start: "2025-02-14",
        end: "2025-02-14",
        type: "kraj"
    },
    {
        name: "Početak nastave u letnjem semestru - sve godine studija",
        start: "2025-02-17",
        end: "2025-02-17",
        type: "pocetak"
    },
    {
        name: "Kraj nastave u letnjem semestru - sve godine studija",
        start: "2025-06-13",
        end: "2025-06-13",
        type: "kraj"
    },
    {
        name: "Nadoknada nastave",
        start: "2025-06-16",
        end: "2025-06-20",
        type: "nadoknada"
    },
    {
        name: "Overa semestra - sve godine studija",
        start: "2025-06-16",
        end: "2025-06-20",
        type: "overaSemestra"
    },
    {
        name: "Aprilski ispitni rok",
        start: "2025-04-02",
        end: "2025-04-11",
        type: "ispitniRok"
    },
    {
        name: "Junski ispitni rok",
        start: "2025-06-16",
        end: "2025-06-27",
        type: "ispitniRok"
    },
    {
        name: "Julski ispitni rok",
        start: "2025-06-30",
        end: "2025-07-11",
        type: "ispitniRok"
    },
    {
        name: "Septembarski ispitni rok",
        start: "2025-09-08",
        end: "2025-09-19",
        type: "ispitniRok"
    },
    {
        name: "Kraj školske 2024/2025. godine",
        start: "2025-09-29",
        end: "2025-09-29",
        type: "kraj"
    }
];
export const holidays = [
    {
        name: "Dan primirja u I svetskom ratu",
        start: "2024-11-11",
        end: "2024-11-11",
        type: "neradniDan"
    },
    {
        name: "Praznici (novogodišnji i božićni)",
        start: "2024-12-30",
        end: "2025-01-10",
        type: "neradniDan"
    },
    {
        name: "Slava Fakulteta",
        start: "2025-02-12",
        end: "2025-02-12",
        type: "neradniDan"
    },
    {
        name: "Dan državnosti",
        start: "2025-02-17",
        end: "2025-02-17",
        type: "neradniDan"
    },
    {
        name: "Uskrs",
        start: "2025-04-18",
        end: "2025-04-21",
        type: "neradniDan"
    },
    {
        name: "Praznik rada",
        start: "2025-05-01",
        end: "2025-05-02",
        type: "neradniDan"
    }
];