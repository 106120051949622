const styles = {
    neradniDan: {
        title: "Neradni dani.",
        backgroundColor: "#287ef9"
    },
    ispitniRok: {
        title: "Ispitni rokovi.",
        backgroundColor: "#9ae4f4"
    },

    nadoknada: {
        title: "Nadoknada nastave.",
        backgroundColor: "#c3e5ae"
    },

    overaSemestra: {
        title: "Overa semestra.",
        backgroundColor: "#a5a4a4"
    },

    pocetak: {
        title: "Pocetak nastave.",
        backgroundColor: "#6b8e23"
    },

    kraj: {
        title: "Kraj semestra, nastave.",
        backgroundColor: "#fed7b8"
    },
    
    multyEvent: (event, index) => {
        let colors = event.map((ev, index) => {
            let color = styles[ev.type].backgroundColor;
            let start = (index / event.length) * 100;
            let end = ((index + 1) / event.length) * 100;
            return `${color} ${start}%, ${color} ${end}%`;
        });
        return {background: `linear-gradient(60deg, ${colors.join(",")})`};
    }
};

export default styles;