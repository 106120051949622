import React from 'react';
import {motion} from "framer-motion";
import {realFilePath} from "../libs/utils";
import {toast} from "react-toastify";

function TeamCard(props) {
    const copyToClipboard = async (email) => {
        await window.navigator.clipboard.writeText(email);
        toast(`Email ${email} is copied.`, {toastId: 0, type: "success"});
    };
    return (
        <>
            <motion.div key={props.index}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{
                            duration: .8,
                            delay: 1 + props.index * 0.4
                        }}

                        className="teamCard">
                <div className="teamCard_img">
                    <img src={realFilePath(props.image)} alt=""/>
                </div>
                <div className="teamCard_info">
                    {/* TODO: Ovde da se sredi teamDetail link* */}
                    <h4 className="teamCard_name">
                        {props.rank && <span>{props.rank}</span>}
                        {props.title && <span>{props.title}</span>}
                        <span>{props.firstName}<br/>{props.lastName}{props.sufix && `, ${props.sufix}`}</span>

                    </h4>
                    {props.funkcija && <p>{props.funkcija}</p>}
                    {props.kontakt && <p>{props.kontakt}</p>}
                    {props.email &&
                        <p style={{cursor: "pointer"}} onClick={() => copyToClipboard(props.email)}>{props.email}</p>}

                </div>
            </motion.div>
        </>
    );
}

export default TeamCard;
