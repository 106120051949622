import React, {useMemo} from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/sr'; // Importovanje srpske lokalizacije
import "./style.scss";
import {events, holidays} from "./prazniciDogadjaji";

import {Tooltip} from "react-tooltip";
import styles from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault("Europe/Belgrade");
dayjs.locale('sr'); // Postavljanje lokalizacije na srpski
const weekDays = [{name: 'P', fill: null}, {name: 'U', fill: null}, {name: 'S', fill: null}, {
    name: 'Č',
    fill: null
}, {name: 'P', fill: null}, {name: 'S', fill: null}, {name: 'N', fill: null}];
let realWeekdays = [...weekDays].slice(0, -1);
realWeekdays.unshift(weekDays[weekDays.length - 1]);
const style = {
    fillWeekend: (day) => {
        return {background: realWeekdays[day].fill};
    }
};

function Calendar({year = 2024, startDay = 30, startMonth = 9}) {
    const range = useMemo(() => {
        return {
            start: dayjs.tz(`${year}-${startMonth}-${startDay}T00:00:00`, "Europe/Belgrade").startOf('month'),
            end: dayjs.tz(`${year + 1}-09-30T00:00:00`, "Europe/Belgrade").endOf('day')
        };
    }, [year, startDay, startMonth]);

    // Kreiranje mapa za praznike i događaje
    const holidayMap = useMemo(() => {
        const map = {};
        holidays.forEach(holiday => {
            const start = dayjs(holiday.start, 'YYYY-MM-DD');
            const end = dayjs(holiday.end, 'YYYY-MM-DD');
            for (let date = start; date.isBefore(end) || date.isSame(end, 'day'); date = date.add(1, 'day')) {
                const dateStr = date.format('YYYY-MM-DD');
                if (!map[dateStr]) map[dateStr] = [];
                map[dateStr].push(holiday);
            }
        });
        return map;
    }, [holidays]);

    const eventMap = useMemo(() => {
        const map = {};
        events.forEach(event => {
            const start = dayjs(event.start, 'YYYY-MM-DD');
            const end = dayjs(event.end, 'YYYY-MM-DD');
            for (let date = start; date.isBefore(end) || date.isSame(end, 'day'); date = date.add(1, 'day')) {
                const dateStr = date.format('YYYY-MM-DD');
                if (!map[dateStr]) map[dateStr] = [];
                map[dateStr].push(event);
            }
        });
        return map;
    }, [events]);

    const combinedMap = useMemo(() => {
        const combined = {...holidayMap};
        for (const [key, value] of Object.entries(eventMap)) {
            if (combined[key]) {
                combined[key] = combined[key].concat(value);
            } else {
                combined[key] = value;
            }
        }
        return combined;
    }, [holidayMap, eventMap]);

    const months = useMemo(() => {
        let days = [];
        let months = [];
        for (let date = range.start; date.isBefore(range.end) || date.isSame(range.end, 'day'); date = date.add(1, 'day')) {
            days.push(
                {
                    day: date
                }
            );
        }
        for (let date = range.start; date.isBefore(range.end) || date.isSame(range.end, 'day'); date = date.add(1, 'month')) {
            months.push(date);
        }
        return months.map((month) => {
            const monthName = dayjs(month).format("MMMM");
            const monthYear = dayjs(month).format("YYYY");
            const monthDays = days.filter(day => day.day.month() === month.month() && day.day.year() === month.year());
            return {
                name: monthName,
                year: monthYear,
                days: monthDays
            };
        });
    }, [range]);

    return (

        <div className="calendar">
            <div className="calendar-wraper">
                {months.map((month) => {
                    return <div className="month-wraper" key={`${month.name}-${month.year}`}>
                        <h3>{month.name} {year}.</h3>
                        <DayNames/>
                        <MonthDay month={month} combinedMap={combinedMap}/>
                    </div>;
                })}
            </div>
            <Legend combinedMap={combinedMap}/>
        </div>

    );
}

function Legend({combinedMap}) {

    return <div className="legenda">
        <p>Legenda</p>
        <ul>
            {Object.keys(styles).map((event) => {
                return styles[event].title && <li key={styles[event].title}>
                    <i data-tooltip-id={styles[event].title} style={styles[event]}></i>
                    <span>{styles[event].title}</span>
                    <Tooltip id={styles[event].title} place={"top-start"} className="tooltip"
                             globalCloseEvents={{scroll: true, resize: true}}>
                        {[...holidays, ...events].filter((el) => el.type === event)
                            .map((el, index) => {
                                return <p key={index}>
                                    <span>{el.name}</span><br/>
                                    <span>{dayjs(el.start).format("DD.MM.YYYY.")}</span>
                                    {el.start !== el.end && <span> - {dayjs(el.end).format("DD.MM.YYYY.")}</span>}
                                </p>;
                            })
                        }
                    </Tooltip>
                </li>;
            })}
        </ul>
    </div>;
}

function DayNames() {
    return <div className="weekDay">
        {weekDays.map((dan, index) => {
            return <div key={dan.name + index} className="weekDay-name">{dan.name}</div>;
        })}
    </div>;
}

function MonthDay({month, combinedMap}) {
    let {days, name} = month;
    // Izračunavanje broja praznih dana pre prvog dana meseca
    const firstDayOfMonth = days[0].day.day(); // 0 = nedelja, 1 = ponedeljak, ..., 6 = subota
    const emptyDaysCount = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    const monthDays = useMemo(() => new Array(emptyDaysCount).fill(null), [emptyDaysCount]);

    return (
        <div className="month">
            {[...monthDays, ...days].map((dayObj, idx) => {
                if (dayObj) {
                    const dateStr = dayObj.day.format('YYYY-MM-DD');
                    const dayEvents = combinedMap[dateStr] || [];
                    let classStyle = {background: "transparent"};
                    if (dayEvents.length > 0 && dayEvents.length === 1) {
                        classStyle = styles[dayEvents[0].type];
                    } else if (dayEvents.length > 1) {
                        classStyle = styles.multyEvent(dayEvents);
                    }
                    return (
                        <div style={classStyle} data-tooltip-id={dayObj.day.format('DD-MM-YYYY')}
                             className={`month-day exist`} key={dateStr}>
                            <p>
                            <span>
                                {dayObj.day.format('D')}
                            </span>
                            </p>
                            {dayEvents.length > 0 &&
                                <Tooltip id={dayObj.day.format('DD-MM-YYYY')} place={"top-start"}
                                         positionStrategy={"absolute"}
                                         className="tooltip">
                                    {dayEvents.map((event, index) => {
                                        return <p className={dayEvents.type} key={index}>
                                            {event.name}
                                        </p>;
                                    })}
                                </Tooltip>}
                        </div>
                    );
                } else {
                    return <div className="month-day empty" key={`empty-${name}-${idx}`}></div>;
                }
            })}
        </div>
    );
}


export default Calendar;