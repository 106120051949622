import React, {useEffect, useRef, useState} from 'react';
import NewsCard from "./NewsCard.jsx";
import NewsService from "../services/NewsService.js";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {BsArrowLeftCircle, BsArrowRightCircle} from "react-icons/bs";
import DOMParse from "html-react-parser";
import {Navigation} from "swiper/modules";
import {Link, useOutletContext} from "react-router-dom";
import {routes} from "../routes/routes.js";
import {useTranslation} from "react-i18next";

function LastNews() {
    const [news, setNews] = useState([]);
    const swiperRef = useRef(null);
    // const {locale} = useParams();
    const locale = useOutletContext();
    const {t} = useTranslation();
    useEffect(() => {
        NewsService.getNews(10, 1, locale)
            .then((res) => {
                setNews(res.data.news);
            })
            .catch((error) => {
                // TODO show error
                console.log(error);
            })
        ;
    }, [locale]);

    return (
        <section className="lastNews py">
            <article className="container">
                <h2>{t("home.latestNews")}</h2>
            </article>
            <article className="container">
                <Swiper slidesPerView={4}
                        modules={[Navigation]}
                        spaceBetween={30}
                        navigation={false}
                        ref={swiperRef}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20
                            }
                        }}
                        className="lastNews_wrapper slider_news">
                    {news.length > 0 && news.map((el) => {
                        return <SwiperSlide key={el.id}>
                            <NewsCard
                                news={el}
                                title={el.naslov}
                                thumbnail={el.attachment} alt="untitled-2-1"
                            >
                                {DOMParse(el.description)}
                            </NewsCard></SwiperSlide>;
                    })}
                </Swiper>
            </article>
            <article className="container lastNews_actions">
                <div className="slider_news-nav">
                    <button
                        onClick={() => swiperRef.current.swiper.slidePrev()}>
                        <BsArrowLeftCircle/>
                    </button>
                    <button
                        onClick={() => swiperRef.current.swiper.slideNext()}>
                        <BsArrowRightCircle/>
                    </button>
                </div>
                <Link className="btn_primary-round-reverse"
                      to={routes.NEWS.realPath(locale)}>{t("buttons.allNews")}</Link>
            </article>
        </section>
    );
}

export default LastNews;