import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import NewsService from "../services/NewsService.js";
import DOMParse from "html-react-parser";
import Hero from "../components/Hero.jsx";
import LastNewsSidebar from "../components/Vesti/LastNewsSidebar.jsx";
import {useDispatch} from "react-redux";
import {setLanguageRelation, toggleLanguageBar} from "../redux/configSlice";
import {realFilePath} from "../libs/utils";

function SnigleNews() {
    const [news, setNews] = useState(null);
    const [attachment, setAttachment] = useState(null);
    const dispatch = useDispatch();
    const router = useNavigate();
    const {id} = useParams();
    const imgElements = [];
    useEffect(() => {
        NewsService.getSingleNews(id)
            .then((res) => {
                console.log(res.data.vest);
                setNews(res.data.vest);
                setAttachment(res.data.attachment);
                dispatch(setLanguageRelation(res.data.vest.translate_relation));
            })
            .catch((error) => {
                console.log(error);
            })
        ;
        return () => dispatch(toggleLanguageBar(true));
    }, [id]);

    // let news = data?.data.vest;
    // let attachment = data?.data.attachment;
    return news && (
        <>
            <Hero title={news.naslov} background={realFilePath(news.thumbnail)}>
                <h1>{news.naslov}</h1>
            </Hero>
            <section className="newsSingle container py">
                <article className="newsSingle_content">
                    <img className="newsSingle_content thumbnail" src={realFilePath(news.thumbnail)} alt=""/>
                    {DOMParse(news.description)}

                    {attachment.map((attach, index) => {
                        if (attach.mimetype.includes("image")) {
                            return <img key={index} src={realFilePath(attach.storeName)} alt={attach.fileName}/>;
                        }
                    })}
                </article>
                <LastNewsSidebar/>
            </section>
        </>
    );
}

export default SnigleNews;