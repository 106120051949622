import React, {useState} from 'react';

import {AiOutlineSearch} from 'react-icons/ai';
import {useSearchParams} from "react-router-dom";

function SearchInput() {
    const [searchParam, setSearchParam] = useSearchParams();
    const [search, setSearch] = useState(searchParam.get("s"));
    const handleClick = () => {
        setSearchParam({page: 1, limit: 12, s: search});
        console.log(searchParam);
    };
    return (
        <div className="searchInput">
            <input className="" placeholder={"Pretraga...."} value={search}
                   onInput={(e) => setSearch(e.target.value)}/>
            <button className="btn btn-sm btn-primary" onClick={handleClick}><AiOutlineSearch/></button>
        </div>
    );
}

export default SearchInput;