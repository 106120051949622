import React from 'react';
import {Link, NavLink} from "react-router-dom";
import LanguageBar from "./LanguageBar.jsx";

function BigMenu({locale, dbMenu, menu}) {
    return menu && (
        <div className="navbar_bigmenu">
            <ul>
                {menu.map((item, key) => {
                        if (parseInt(item.drop)) {
                            return <li key={key} className="navbar_bigmenu-drop">
                                <span>{item.caption}</span>
                                <div className="navbar_bigmenu-drop_menu">
                                    {
                                        item.submenu && item.submenu.map((subItem, subKey) => {
                                            if (parseInt(subItem.drop)) {
                                                return <div key={subKey}>
                                                    <span>{subItem.caption}</span>
                                                    <ul>
                                                        {subItem.submenu.map((ssubItem, ssubKey) => {
                                                            return <li key={ssubKey}><Link
                                                                to={ssubItem.to}>{ssubItem.caption}</Link>
                                                            </li>;
                                                        })}
                                                    </ul>
                                                </div>;
                                            } else {
                                                return <Link key={subKey}
                                                             to={subItem.to}>{subItem.caption}
                                                </Link>;
                                            }
                                        })
                                    }
                                </div>
                            </li>;
                        } else {
                            return <li key={key}><NavLink to={item.to}>{item.caption}</NavLink>
                            </li>;
                        }
                    }
                )
                }
                <li className="navbar_bigmenu_language"><LanguageBar locale={locale}/></li>
            </ul>
        </div>
    );
}

export default BigMenu;