import React from 'react';
import Calendar from "../../components/Calendar/Calendar";
import Hero from "../../components/Hero";

function WorkCalendar() {
    return (
        <>
            <Hero title={"Work calendar"}>
                
            </Hero>
            <section className="container">
                <Calendar/>
            </section>
        </>
    );
}

export default WorkCalendar;