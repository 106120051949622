import React from 'react';
import {Link, useOutletContext, useSearchParams} from "react-router-dom";
import "./_pagination.scss";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa6";
import {routes} from "../../routes/routes";

function Pagination({count, numAroundItems = 1, currentPage, limit = 12}) {
    const lang = useOutletContext();
    const [searchParam, setSearchParam] = useSearchParams();
    let numberPage = Math.ceil(count / limit);
    let aroundItems = numAroundItems * 2 + 1;
    let itemArray = Array(aroundItems).fill(startNumber());
    currentPage = parseInt(currentPage);

    function startNumber() {
        if (currentPage <= numAroundItems) {
            return 1;
        } else if (currentPage >= numberPage - numAroundItems) {
            return numberPage - numAroundItems * 2;
        } else {
            return currentPage - numAroundItems;
        }
    }

    const previusPage = () => {
        searchParam.set("page", currentPage - 1 < 1 ? 1 : currentPage - 1);
        setSearchParam(searchParam);
    };

    const nextPage = () => {
        searchParam.set("page", currentPage + 1 > numberPage ? numberPage : currentPage + 1);
        setSearchParam(searchParam);
    };

    const changePage = (page) => {
        searchParam.set("page", page);
        setSearchParam(searchParam);
    };

    return (
        <div className="pagination">
            <ul>
                <li>
                    <button className="box" style={{cursor: "pointer"}} onClick={previusPage}>
                        <FaAngleLeft/>
                    </button>
                </li>
                {itemArray.map((el, index) => {
                    return <li key={index}
                               className={parseInt(currentPage) === el + index ? "active" : ""}>
                        <button className="box" style={{cursor: "pointer"}} onClick={() => changePage(el + index)}>
                            {el + index}
                        </button>
                    </li>;
                })}
                {
                    currentPage < numberPage - numAroundItems &&
                    (
                        <>
                            <li><span className="box">...</span></li>
                            <li>
                                <button className="box" style={{cursor: "pointer"}}
                                        onClick={() => changePage(numberPage)}>
                                    {numberPage}
                                </button>
                            </li>
                        </>
                    )
                }

                <li>
                    <button className="box" style={{cursor: "pointer"}} onClick={nextPage}>
                        <FaAngleRight/>
                    </button>

                </li>

            </ul>

        </div>
    );
}

export default Pagination;