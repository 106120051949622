import React, {useRef} from 'react';
import {motion, useInView} from "framer-motion";
import {fromTop} from "../../libs/motionVarianst.js";
import {realFilePath} from "../../libs/utils";
import {toast} from "react-toastify";

function NenastavnoItem({osoblje, kategorija, inline = true}) {
    const ref = useRef(null);
    const inView = useInView(ref, {once: false, amount: "some"});
    const copyToClipboard = async (email) => {
        await window.navigator.clipboard.writeText(email);
        toast(`Email ${email} is copied.`, {toastId: 0, type: "success"});
    };
    return <motion.article ref={ref} className="wrapper_item"
                           variants={fromTop}
                           initial={!inView ? "visible" : "hidden"}
                           animate={inView ? "visible" : "hidden"}
                           transition={{
                               type: "spring",
                               duration: 1,
                               bounce: .5
                           }}
    >
        <div className="card">
            <h2 className="title">{kategorija}</h2>
            <div>
                {osoblje[kategorija].map((osoba, index) => {
                    return <div className="card_body" key={index}>
                        <img src={realFilePath(osoba.image)} alt={osoba.firstName}/>
                        <div className="card_body_items">
                            <h3 className="ime">
                                {inline && <span>{osoba.title} </span>}
                                <span>{osoba.firstName}</span> <span>{osoba.lastName}</span>
                            </h3>
                            {!inline && <p className="role">{osoba.title}</p>}

                            <p className="obrazovanje">{osoba.obrazovanje}</p>

                            <p className="email" style={{cursor: "pointer"}}
                               onClick={() => copyToClipboard(osoba.email)}>{osoba.email}</p>

                        </div>
                    </div>;
                })}
            </div>
        </div>
    </motion.article>;
}

export default NenastavnoItem;