import React, {useEffect} from 'react';
import {useMutation} from "@tanstack/react-query";
import NewsService from "../services/NewsService.js";
import NewsCard from "../components/NewsCard.jsx";
import DOMParse from "html-react-parser";
import Hero from "../components/Hero.jsx";
import {useOutletContext, useSearchParams} from "react-router-dom";
import Pagination from "../components/Pagination/Pagination.jsx";
import {useDispatch} from "react-redux";
import {closeLoader, showLoader} from "../redux/preloaderSlice.js";
import {useTranslation} from "react-i18next";
import {realFilePath} from "../libs/utils";
import SearchInput from "../components/SearchInput/SearchInput";
import Error204 from "./Errors/Error204/Error204";


function AllNews() {
    const [searchParam, setSearchParam] = useSearchParams();
    const dispatch = useDispatch();

    const lang = useOutletContext();
    const {t} = useTranslation("locals", {keyPrefix: "news"});
    const {data, mutate, isSuccess, isPending} = useMutation({
        mutationKey: ["news"],
        mutationFn: (page) => {
            dispatch(showLoader());
            if (searchParam.has("s")) {
                return NewsService.search(searchParam.get("s"), page, 12, lang);
            } else {
                return NewsService.getNews(12, page, lang);
            }
        },
        onSuccess: (data) => {
            dispatch(closeLoader());
        }
    });

    useEffect(() => {
        let currentPage = searchParam.has("page") ? searchParam.get("page") : 1;
        mutate(currentPage);
    }, [searchParam, lang]);

    let newsResponse = isSuccess && data.data.news;
    let newsCount = isSuccess && data.data.count;

    return (
        <>
            <Hero title="Vesti" background={realFilePath("news_hero.jpg")} style={{maxHeight: "60vh"}}>
                <h1>{t("title")}</h1>
            </Hero>
            {newsCount > 0 ? <section className="allNews py">
                <article className="allNews_pagination container">
                    <Pagination count={newsCount}
                                numAroundItems={1}
                                currentPage={searchParam.has("page") ? searchParam.get("page") : 1}/>
                    <SearchInput/>
                </article>
                <article className="allNews_wrapper container">
                    {newsResponse.map((news, index) => {
                        return <NewsCard
                            news={news}
                            title={news.naslov}
                            thumbnail={news.attachment} alt="untitled-2-1"
                            key={index}
                        >
                            {DOMParse(news.description)}
                        </NewsCard>;
                    })}
                </article>
            </section> : <Error204/>}
        </>
    );
}

export default AllNews;