import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {FaBars} from "react-icons/fa6";
import MobileMenu from "./MobileMenu.jsx";
import useWindowSize from "../../hooks/useWindowSize.js";
import useWindowScroll from "../../hooks/useWindowScroll.js";
import {routes} from "../../routes/routes.js";
import BigMenu from "./BigMenu.jsx";
import LanguageBar from "./LanguageBar";
import {realFilePath} from "../../libs/utils";
import {useSelector} from "react-redux";
import Init from "../../services/Init";

const style = {};

function Navbar({lang}) {
    const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
    const [windowWidth] = useWindowSize();
    const [scrollX, scrollY] = useWindowScroll();
    const [dbMenu, setDbMenu] = useState();
    const {locale} = useParams();
    const [menu, setMenu] = useState([]);

    const {navbar} = useSelector((state) => state.configStore);

    useEffect(() => {
        Init.navMenuItem(lang)
            .then((res) => {
                setMenu(res.data.menu);
            })
            .catch((error) => {
                console.log(error);
            })
        ;
    }, [locale, lang]);

    const handleMobilMenu = () => {
        setToggleMobileMenu(!toggleMobileMenu);
    };

    return lang && (
        <nav style={
            (scrollY > 10 || !navbar.dark) ? {} : {background: "#072248"}

        } className={`${scrollY > 10 ? "navbar scrolled" : "navbar"}`}>
            {/*<TopBar/>*/}
            <div className="navbar_nav container">
                <div className="navbar_brand">
                    <Link to={routes.HOME.realPath(lang ? lang : "srb")}>
                        <img src={realFilePath("logo.png")} alt=""/>
                    </Link>
                </div>
                {windowWidth > 900 ?
                    <BigMenu locale={lang} dbMenu={dbMenu} menu={menu}/> :
                    <>
                        <MobileMenu
                            menu={menu} toggle={setToggleMobileMenu} state={toggleMobileMenu}/>
                        <div className="navbar_hamburger">
                            <button onClick={handleMobilMenu}><FaBars/></button>
                            <button className="navbar_bigmenu_language"><LanguageBar locale={lang}/></button>
                        </div>
                    </>
                }
            </div>
        </nav>
    );
}

export default Navbar;