import React from 'react';
import {motion} from "framer-motion";
import {useSearchParams} from "react-router-dom";
import {opacityIn} from "../../libs/motionVarianst.js";
import TeamCard from "../../components/TeamCard.jsx";

function OsobljeKategorijaItem({data}) {
    const [params] = useSearchParams();

    const kategorija = params.get("category");

    return kategorija && data[kategorija].map((osoba, index) => {
        return <motion.div
            key={kategorija + index}
            variants={opacityIn}
            initial={"hidden"}
            animate={"visible"}>

            <TeamCard {...osoba} />

        </motion.div>;
    });

}

export default OsobljeKategorijaItem;