import axios from "axios";

class NewsService {
    static getNews = (limit = null, offset = null, lang = "srb") => axios.get(`/news?lang=${lang}&limit=${limit}&offset=${offset}`);
    static addNews = (body) => axios.post("/news/add", body);
    static getSingleNews = (id) => axios.get("/news/getSingle/" + id);
    static search = (term, offset, limit = 12, lang = "srb") => axios.get(`/news/search/${term}?lang=${lang}&limit=${limit}&offset=${offset}`);
    ;


}

export default NewsService;